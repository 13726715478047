<template>
  <div class="content plat-user-member-list-page">
    <!-- 用户界面 -->
    <div>
      <div class="search">
        <!-- <el-input placeholder="用户ID" v-model="user_id" clearable style="width:160px">
        </el-input> -->
        <el-form :inline="true" class="mt10" size="medium">
          <el-form-item>
            <el-input
              placeholder="商户名称/电话"
              v-model="form.tel"
              clearable
              style="width: 160px"
              @keyup.enter.native="search"
            >
            </el-input>
            <el-input
              placeholder="业绩归属人/手机号"
              v-model="form.tel"
              clearable
              style="width: 160px"
              @keyup.enter.native="search"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.state"
              placeholder="归属分公司"
              clearable
              style="width: 160px"
            >
              <el-option value="0" label="全部状态">全部状态</el-option>
              <el-option value="1" label="正常">正常</el-option>
              <el-option value="2" label="禁用">禁用</el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">搜索</el-button>
            <el-button type="info" @click="clear('clear')">清空</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableStyle">
        <el-table :data="tableData" border stripe style="width: 100%">
          <el-table-column prop="createTime" label="注册时间"></el-table-column>
          <el-table-column
            prop="userId"
            label="UID"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="nickName"
            label="昵称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="tel"
            label="手机号码"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="gender"
            label="性别"
            align="center"
          ></el-table-column>
          <el-table-column prop="state" label="状态" align="center">
            <template slot-scope="scope">
              <p :class="scope.row.state == '2' ? 'red' : 'green'">
                {{ scope.row.state == '2' ? '禁用' : '正常' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="150"
            fixed="right"
          >
            <template slot-scope="scope">
            
                <span
                  @click="changStatus(scope.row)"
                  style="color: red; cursor: pointer"
                  slot="reference"
                  >停业</span
                >
           
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        background
        :page-count="totalPages"
        :current-page.sync="currentPage"
        @current-change="getTableList"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { $sg_getSpreadVipListV2 } from '@/api/setting';

export default {
  name: 'PlatuserMemberlist',

  data() {
    return {
      tableData: [],
      totalPages: null,
      currentPage: 1,
      form: {
        startTime: null,
        endTime: null,
        state: null,
        tel: null,
        time: null,
      },
    };
  },
  components: {
    // ExportTips,
  },
  computed: {
    typeOptions() {
      return this.scopeType.map((item, index) => ({
        label: item,
        value: index + 1,
      }));
    },
  },
  methods: {
    search() {},
    getTableList() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 10,
      };
      $sg_getSpreadVipListV2(params).then((res) => {
        this.tableData = res.records;
        this.totalPages = res.pages;
        this.totalVipNums = res.totalVipNums;
        let text = '平台注册总会员数';
        switch (this.user_type) {
          case '1':
            text = '平台注册普通会员数';
            break;
          case '2':
            text = '平台注册充值会员数';
            break;
          default:
            break;
        }
        this.totalVipName = text;
      });
    },
  },
  created() {
    this.getTableList();
  },
};
</script>
<style lang="scss">
.plat-user-member-list-page {
  .content {
    width: 100%;
    height: 100%;
    background: #fff;
    background: #fff;
  }
  // 资金记录弹窗
  .tableStyle {
    margin: 10px 0;
  }
  .search {
    background: #fff;

    padding: 20px 10px 10px 10px;
  }
  .codeVisible {
    .el-dialog__body {
      padding-left: 30px;
    }
  }
  .detail {
    .el-dialog {
      margin-top: 7vh !important;
    }
  }
  .modify-div {
    background-color: #f2f2f2;
    padding: 10px;
    .inline-input {
      width: 40%;
      margin: 0 10px;
    }
    .remark-input {
      width: 96%;
    }
    .vip-select {
      width: 100px;
    }
  }
  .import-vip-users {
    .import-normal {
      display: flex;
      flex-direction: row;
      padding: 10px;
      box-sizing: border-box;
      h4 {
        margin: 0;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .line-cross {
      width: 100%;
      height: 1px;
      background-color: #ccc;
    }
    .import-notice {
      padding: 0 10px;
      box-sizing: border-box;
      line-height: 30px;
      font-weight: 400;
      font-size: 14px;
      color: rgb(16, 16, 16);
      a {
        text-decoration: underline;
        color: rgba(1, 134, 146, 1);
      }
    }
    .choose-file {
      padding: 10px;
      button {
        height: 28px;
      }
    }
    .import-text {
      width: 800px;
      height: 40px;
      line-height: 40px;
      background-color: rgb(229, 229, 229);
      padding-left: 6px;
      margin-left: 10px;
      margin-bottom: 12px;
      span {
        font-weight: 700;
        font-size: 16px;
      }
      .success-num {
        color: rgba(37, 155, 36, 1);
      }
      .fail-num {
        color: rgb(229, 28, 35);
      }
    }
    .page-btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 20px;
      box-sizing: border-box;
      margin-bottom: 8px;
      // button{
      //   height: 28px;
      // }
    }
  }
}
</style>
