import api from './api';

/**
 * 发单配置
 */

export const $sp_saveSendConfig = (params) => api.post('/shop/v1/Config/saveSendConfig', params);
// 第三方对接
export const $sg_getThirdSendConfig = (params) => api.get('/shop/v1/Config/getSendConfig', params);

/**
 * 无线打印机
 */

// 平台获取商品分类
export const $sg_getGoodsSort = (params) => api.get('/mall/v1/GoodsManage/getGoodsSort', params);
export const $sg_printerList = (params) => api.get('/shop/v2/wirelessPrinter/printerList', params);
export const $sg_delPrinter = (params) => api.post('/shop/v2/wirelessPrinter/delPrinter', params);
export const $sg_addPrinter = (params) => api.post('/shop/v2/WirelessPrinter/addPrinter', params);
// 保存店长的打印机配置
export const $sg_savePrinter = (params) => api.get('/j/mch_printer_config/update', params);
export const $sg_queryPrinter = (params) => api.get('/j/mch_printer_config/query', params);
export const $sg_saveConfig = (params) => api.post('/shop/v2/wirelessPrinter/saveConfig', params);
export const $sg_getPrinter = (params) => api.get('/shop/v1/Printer/getPrinter', params);
export const $sg_getYiLianYunAuthorUrl = (params) => api.get('/shop/v2/wirelessPrinter/getYiLianYunAuthorUrl', params);

/**
 * 铃声设置
 */

export const $sg_shop_setShopConfig = (params) => api.post('/mall/v3/shop/setShopConfig', params);
// 获取外送配置
export const $sg_getMallShopConfigs = (params) => api.get('/mall/v2/MallShop/getMallShopConfig', params);


export const $sg_addMch_printer = (params) => api.post('/j/mch_printer/add', params);

export const $sg_updateMch_printer = (params) => api.post('/j/mch_printer/update', params);
export const $sg_delete_printer = (params) => api.get('/j/mch_printer/delete', params);

// export const $sg_query_printer = (params) => api.postNoMessage('http://o2o-java.xd0.co:9223/j/mch_printer/query', params);

export const $sg_query_printer = (params) => api.postNoMessage('/j/mch_printer/query', params);


export const $sg_toWechatAuthorizePage = (params) => api.postNoMessage('/j/shop/auth/wechat/toWechatAuthorizePage', params);
export const $sg_wechat_getShopDetail = (params) => api.postNoMessage('/j/shop/auth/wechat/getShopDetail', params);


export const $sg_getDeductionConfig = (params) => api.get('/mch/v1/ScoreCard/getCardScoreRule', params);
export const $sg_saveDeductionConfig = (params) => api.post('/mch/v1/ScoreCard/saveCardScoreRule', params);
export const $sg_queryByShopId = (params) => api.get('/j/mch_mini_app/queryByShopId', params);
export const $sg_saveOrUpdate = (params) => api.post('/j/mch_mini_app/saveOrUpdate', params);
export const $sg_shopUnBind = (params) => api.post('/j/shop/auth/wechat/shopUnBind', params);
// 提现部分
export const $sg_getShops = (params) => api.get('/j/platform/withdraw/queryWithdrawShopNames', params);
export const $sg_countApplyingWithdraw = () => api.get('/mall/v1/shopBalance/countApplyingWithdraw');
export const $sg_getShopWithdraws = (params) => api.get('/j/platform/withdraw/queryWidthdrawList', params);
export const $sp_spreadRejectShopApply = (params) => api.post('/j/platform/withdraw/verifyShopState', params);
export const $sp_agentAgreeWithdraw = (params) => api.post('/agent/v1/fund/agreeWithdraw', params);
export const $sp_agentRejectWithdraw = (params) => api.post('/agent/v1/fund/rejectWithdraw', params);
export const $sg_listWithdraws = (params) => api.get('/mch/v1/Fund/listSpreadWithdraws', params);
export const $sp_agentGetWithdrawAccountInfo = (params) => api.get('/agent/v1/fund/getWithdrawAccountInfo', params);

export const $sp_queryWidthdrawList = (params) => api.get('/j/platform/agent_withdraw/queryWidthdrawList', params);
export const $sp_queryWithdrawDetail = (params) => api.get('/j/platform/agent_withdraw/queryWithdrawDetail', params);
export const $sp_verify = (params) => api.post('/w/admin/withdraw/verify', params);
export const $sp_withdrawFix = (params) => api.post('/j/platform/agent_withdraw/withdrawFix', params);
// 用户列表部分
export const $sg_getExportPageList = (params) => api.get('/j/platform/t-spread-platform-user/page', params);
// 获取平台今天已导出数量
export const $sg_getTodaySpreadExportCount = (params) => api.get('/j/platform/v1/SpreadExport/getTodaySpreadExportCount', params);
// 重新导出
export const $sp_reExport = (params) => api.post('/platform/v1/SpreadExport/reExport', params);
// 获取所有成员列表v2
export const $sg_getSpreadVipListV2 = (params) => api.get('/j/platform/t-user/page', params);
// 修改平台普通会员卡的状态
export const $sp_saveSpreadPlatUser = (params) => api.post('/j/platform/v1/SpreadUser/saveSpreadPlatUser', params);
// 平台设置->平台权限->获取平台是否配置授权码
export const $sg_getSpreadAuthorizedCode = () => api.get('/platform/v1/SpreadInfo/getSpreadAuthorizedCode');
// 验证平台授权码是否正确
export const $sp_checkSpreadAuthorizedCode = (params) => api.post('/platform/v1/SpreadInfo/checkSpreadAuthorizedCode', params);

// 获取普通会员资金明细
export const $sg_getSpreadPlatUserDetailed = (params) => api.get('/platform/v1/SpreadUser/getSpreadMemberDetailed', params);
export const $sp_spreadSaveUserMoneyOrScore = (params) => api.post('/platform/v1/SpreadUser/spreadSaveUserMoneyOrScore', params);
// 获取平台列表
export const $sg_getScoreList = (params) => api.get('/platform/v1/SpreadUser/getScoreList', params);
// 导入平台会员失败记录
export const $sg_getMigrateVipFailList = (params) => api.get('/platform/v1/SpreadUser/getMigrateVipFailList', params);
// 清除失败记录
export const $sg_clearMigrateVipHistroy = () => api.get('/platform/v1/SpreadUser/clearMigrateVipHistroy');

// 配送设置新增
export const $sp_psConfigAdd = (params) => api.postNoTips('/j/distribution/agent/distribution/config/platform/add', params);
// 配送设置查询
export const $sg_psConfigGet = (params) => api.get('/j/distribution/agent/distribution/config/platform/get', params);
// 配送设置修改
export const $sp_psConfigUpdate = (params) => api.postNoTips('/j/distribution/agent/distribution/config/platform/update', params);
